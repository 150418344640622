@mixin accessible-focus-state() {
  &:focus-visible {
    @apply outline-none bg-highlight no-underline;
    @apply text-black #{!important};
    @apply shadow-[0_-2px_0_0_theme(colors.highlight),0_4px_0_0_theme(colors.black)];

    &:not(.no-path-fill) {
      path {
        @apply fill-black #{!important};
      }
    }

    &:hover {
      @apply bg-highlight;
    }
  }
}

a,
button,
.button  {
  @include accessible-focus-state();
}

/* Fallback for browsers without :focus-visible support */
@supports not selector(:focus-visible) {
  a,
  button,
  .button  {
    @include accessible-focus-state();
  }
}

.visually-hidden {
  @apply absolute -left-[9999px] top-auto w-[1px] h-[1px] overflow-hidden;
}

@media (prefers-reduced-motion: reduce) {
  * {
    @apply animate-none #{!important};
    @apply transition-none #{!important};
  }
}

.skip-link:focus-visible {
  @include accessible-focus-state();
  @apply fixed top-[1px] left-[1px] w-auto h-auto z-[99];
}

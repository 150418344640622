.accordion {
  &__icon:not(.no-icon-rotate) {
    @apply rotate-0;
    
    .expanded & {
      @apply rotate-180;
    }
  }
}

.matrix--accordion + .matrix--accordion {
  .accordion {
    @apply border-t-0 -mt-10 md:-mt-10-lg;
  }
}
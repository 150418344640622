.matrix {
  &:first-child {
    & > div:first-child > div:first-child {
      @apply mt-0;

      & > *:first-child {
        @apply mt-0;
      }
    }
  }

  &:last-child {
    & > div:last-child > div:last-child {
      @apply mb-0;

      & > *:last-child {
        @apply mb-0;
      }
    }
  }
}

.matrixes {
  & > .section {
    @apply pb-[calc(40px+16px)] sm:pb-[calc(40px+24px)] md:pb-[calc(104px+32px)] lg:pb-[calc(104px+48px)];

    &:last-child {
      @apply py-[40px] md:py-[104px];
    }
  }
}
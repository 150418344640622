.site-header.active {
  .site-header--top {
    @apply bg-off-white-light nav-md:bg-off-white;
  }
}

.burger-menu {
  &--close {
    @apply hidden;
  }

  &.active {
    .burger-menu--open {
      @apply hidden;
    }

    .burger-menu--close {
      @apply block;
    }
  }
}

.site-header--nav {
  @apply relative hidden;

  &.active {
    @apply block;
  }
}

.header-nav--overlay {
  @apply bg-off-white-light bg-texture hidden;

  &.active {
    @apply block;
  }
}
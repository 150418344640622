input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
  @apply hidden;
}

input[type="date"],
input[type="email"],
input[type="number"],
input[type="password"],
input[type="search"],
input[type="tel"],
input[type="text"],
input[type="url"],
select,
textarea {
  @apply px-[8px] py-[4px] border-2 border-black w-full bg-off-white-light;
  @apply mb-7 md:mb-7-lg;

  &:focus {
    @apply outline-none;
    @apply shadow-[0_0_0_4px_theme(colors.highlight),inset_0_0_0_2px_theme(colors.black)];
  }
}

input[type="date"],
input[type="email"],
input[type="number"],
input[type="password"],
input[type="search"],
input[type="tel"],
input[type="text"],
input[type="url"],
select {
  @apply max-w-[472px];
}

input[type="radio"],
input[type="checkbox"] {
  @apply inline-flex flex-shrink-0 w-[4rem] h-[4rem] justify-center items-center appearance-none outline-none bg-white border-2 border-solid border-black;

  &:before {
    @apply content-[''] block opacity-0;
  }

  &:checked:before {
    @apply opacity-100;
  }

  &:focus-visible {
    @apply shadow-[0_0_0_4px_theme(colors.highlight),inset_0_0_0_2px_theme(colors.black)]
  }
}

input[type="radio"] {
  @apply rounded-full;

  &:before {
    @apply w-[2rem] h-[2rem] rounded-full bg-black;
  }
}

input[type="checkbox"] {
  &:before {
    @apply w-[2.4rem] h-[1.8rem] bg-[url('/dist/images/icons/checkbox-tick.svg')] bg-no-repeat bg-center bg-contain;
  }
}

textarea {
  @apply min-h-[180px];
}

label {
  @apply font-function block mb-[8px];
}
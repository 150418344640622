p.first-letter > .letter-container {
  @apply relative w-[106px] h-[106px] float-left mt-[6px] mr-sm mb-xs;
  @apply md:w-[200px] md:h-[200px] md:mt-[7px] md:mr-lg md:mb-sm;

  .letter {
    @apply w-[96px] h-[96px] flex items-center justify-center absolute top-0 right-0 text-[80px] font-bold text-off-white-light bg-red-bright bg-texture-lighter;
    @apply md:w-[184px] md:h-[184px] md:text-[150px];
  }

  .shadow {
    @apply w-[96px] h-[96px] absolute left-0 bottom-0 bg-black bg-texture-light;
    @apply md:w-[184px] md:h-[184px];
  }
}

p.indent-para {
  @apply relative py-3xs pl-[26px];
  @apply md:py-xs md:pl-[30px];

  &:before {
    @apply content-[''] absolute block top-0 bottom-0 left-[2px] w-[8px] bg-black bg-texture-light;
  }
}

.matrix--text {
  // paragraph heading style
  h3.heading-2xs {
    @apply font-bold mt-10 mb-6 last:mb-8 md:mt-10-lg md:mb-6-lg md:last:mb-8-lg;
  }
}

.first-letter-container {
  @apply min-h-[106px] md:min-h-[200px];
}
.bg-off-white-light .matrix--image .bg-diagonal {
  @apply bg-diagonal-up-off-white-light;
}

.bg-off-white .matrix--image .bg-diagonal {
  @apply bg-diagonal-up-off-white;
}

.bg-light-blue .matrix--image .bg-diagonal {
  @apply bg-diagonal-up-light-blue;
}

.bg-gold-grey .matrix--image .bg-diagonal {
  @apply bg-diagonal-up-gold-grey;
}
@layer components {
  // layout 
  .container {
    @apply max-w-2xl mx-auto px-xs sm:px-md md:px-xl lg:px-2xl;
  }
  .section {
    @apply bg-texture py-[40px] md:py-[104px];
  }
  // for blocks like the accordion
  .container .container {
    @apply px-0;
  }
}
@charset "UTF-8";
@keyframes plyr-progress {
	to {
		background-position: 25px 0;
		background-position: var(--plyr-progress-loading-size,25px) 0;
	}
}
@keyframes plyr-popup {
	0% {
		opacity: .5;
		transform: translateY(10px);
	}
	to {
		opacity: 1;
		transform: translateY(0);
	}
}
@keyframes plyr-fade-in {
	0% {
		opacity: 0;
	}
	to {
		opacity: 1;
	}
}

.plyr {
	[data-plyr=captions] {
		@apply hidden;
	}
	[data-plyr=fullscreen] {
		@apply hidden;
	}
	[data-plyr=pip] {
		@apply hidden;
	}
}

.plyr__control {
	@apply relative p-0 w-[40px] h-[52px] sm:w-[52px] sm:flex-shrink-0;

	&:not([data-plyr="play"]) {
		@apply button-reset;
	}

	svg {
		@apply h-[20px] w-[20px] pointer-events-none;
	}

	&:not(.plyr__control--pressed) {
		.icon--pressed {
			@apply hidden;
		}
		.label--pressed {
			@apply hidden;
		}
	}
}

.plyr__control.plyr__control--pressed {
	.icon--not-pressed {
		@apply hidden;
	}
	.label--not-pressed {
		@apply hidden;
	}
}

.plyr__controls {
	@apply relative flex items-center justify-end text-center ml-[59px] mb-[8px];
	@apply border-2 border-black h-[52px];
	@apply bg-texture bg-off-white-light;

	&:before {
		@apply content-[''] block absolute -top-[2px] -right-[2px] -bottom-[10px] -left-[10px];
		@apply bg-[url:theme("backgroundImage.btn-left"),theme("backgroundImage.btn-bottom")];
		@apply bg-[length:8px_auto,auto_8px];
		@apply bg-[position:left_top,right_bottom];
		@apply bg-no-repeat;
	}

	.plyr__progress__container {
		@apply flex-1 min-w-0;
	}
	.plyr__controls__item {
		&[data-plyr="play"] {
			@apply absolute -top-[2px] -left-[60px] mr-0 w-[52px];

			&.plyr__control--pressed {
				@apply -translate-x-[8px] translate-y-[8px] bg-off-white-dark;

				&:before {
					@apply bg-none;
				}
			}
		}
	}
	.plyr__controls__item.plyr__progress__container {
		@apply pl-[20px]
	}
	.plyr__controls__item.plyr__time {
		@apply w-[42px] px-[2px] text-right sm:w-[56px] sm:px-[8px];
	}

	&:empty {
		@apply hidden;
	}

	>.plyr__control {
		&:first-child {
			.plyr__tooltip {
				transform: translateY(10px) scale(.8);
				transform-origin: 0 100%;
				@apply left-0;

				&:before {
					@apply left-[16px];
				}
			}

			.plyr__tooltip--visible {
				transform: translate(0) scale(1);
			}

			&:focus-visible {
				.plyr__tooltip {
					transform: translate(0) scale(1);
				}
			}

			&:hover {
				.plyr__tooltip {
					transform: translate(0) scale(1);
				}
			}
		}
		&:first-child+.plyr__control {
			.plyr__tooltip {
				transform: translateY(10px) scale(.8);
				transform-origin: 0 100%;
				@apply left-0;

				&:before {
					@apply left-[16px];
				}
			}
			.plyr__tooltip--visible {
				transform: translate(0) scale(1);
			}
			&:focus-visible {
				.plyr__tooltip {
					transform: translate(0) scale(1);
				}
			}
			&:hover {
				.plyr__tooltip {
					transform: translate(0) scale(1);
				}
			}
		}
		&:last-child {
			.plyr__tooltip {
				transform: translateY(10px) scale(.8);
				transform-origin: 100% 100%;
				@apply left-auto right-0;

				&:before {
					right: calc(var(--plyr-control-icon-size,18px)/2 + var(--plyr-control-spacing,10px)*.7);
					transform: translateX(50%);
					@apply left-auto right-[16px];
				}
			}
			.plyr__tooltip--visible {
				transform: translate(0) scale(1);
			}
			&:focus-visible {
				.plyr__tooltip {
					transform: translate(0) scale(1);
				}
			}
			&:hover {
				.plyr__tooltip {
					transform: translate(0) scale(1);
				}
			}
		}
	}
}

.plyr__menu {
	@apply relative flex;

	.plyr__control {
		svg {
			transition: transform .3s ease;
		}
	}
	.plyr__control[aria-expanded=true] {
		svg {
			transform: rotate(90deg);
		}
		.plyr__tooltip {
			@apply hidden;
		}
	}
}

.plyr__menu__container {
	animation: plyr-popup .2s ease;
	@apply bg-white/95 rounded-[8px] text-black text-xs shadow-sm mb-[10px] whitespace-nowrap absolute bottom-full -right-[3px] text-left z-30;

	>div {
		overflow: hidden;
		transition: height .35s cubic-bezier(.4,0,.2,1),width .35s cubic-bezier(.4,0,.2,1);
	}

	&:after {
		border: 4px solid #0000;
		border-top-color: #ffffffe6;
		@apply content-[''] absolute h-0 w-0 right-[14px] top-full;
	}

	button {
		@apply h-auto;
	}

	[role=menu] {
		@apply p-[7px];
	}

	[role=menuitem] {
		@apply mt-[2px];

		&:first-child {
			@apply mt-0;
		}
	}

	[role=menuitemradio] {
		@apply mt-[2px];

		&:first-child {
			@apply mt-0;
		}
	}
	.plyr__control {
		-webkit-user-select: none;
		user-select: none;
		@apply flex items-center text-black text-xs px-[10px] py-[5px] w-full;

		>span {
			align-items: inherit;
			@apply flex w-full;
		}

		&:after {
			border: 4px solid #0000;
			transform: translateY(-50%);
			@apply content-[''] absolute top-1/2;
		}
	}

	.plyr__control--forward {
		@apply pr-[28px];

		&:after {
			border-left-color: #728197;
			border-left-color: var(--plyr-menu-arrow-color,#728197);
			@apply right-[6.5px];
		}

		&:focus-visible {
			&:after {
				border-left-color: initial;
			}
		}

		&:hover {
			&:after {
				border-left-color: initial;
			}
		}
	}
	.plyr__control--back {
		@apply relative font-normal m-[7px] mb-[3.5px] pl-[28px] w-[calc(100%-14px)];

		&:after {
			@apply left-[6.5px] border-r-[#728197];
		}

		&:before {
			box-shadow: 0 1px 0 #fff;
			@apply content-[''] absolute right-0 top-full bg-grey w-full h-[1px] left-0 mt-[3.5px] overflow-hidden bg-none;
		}

		&:focus-visible {
			&:after {
				border-right-color: initial;
			}
		}

		&:hover {
			&:after {
				border-right-color: initial;
			}
		}
	}

	.plyr__control[role=menuitemradio] {
		@apply pl-[7px];

		&:after {
			transform: translateY(-50%) scale(0);
			transition: transform .3s ease,opacity .3s ease;
			@apply static rounded-full bg-white border-0 h-[6px] w-[6px] left-[12px] opacity-0 top-1/2;
		}

		&:before {
			transition: all .3s ease;
			@apply static rounded-full bg-light-blue content-[''] block flex-shrink-0 h-[12px] w-[12px] mr-[10px] bg-none;
		}

		&:focus-visible {
			&:before {
				background: #23282f1a;
			}
		}
		&:hover {
			&:before {
				background: #23282f1a;
			}
		}
	}
	.plyr__control[role=menuitemradio][aria-checked=true] {
		&:before {
			@apply bg-teal-dark;
		}
		&:after {
			opacity: 1;
			transform: translateY(-50%) scale(1);
		}
	}
	.plyr__menu__value {
		@apply flex items-center ml-auto -mr-[5px] overflow-hidden pl-[24.5px] pointer-events-none;
	}
}

.plyr__time {
	@apply text-[13px];
}

.plyr__tooltip {
	transform: translate(-50%,10px) scale(.8);
	transform-origin: 50% 100%;
	transition: transform .2s ease .1s,opacity .2s ease .1s;
	@apply bg-white rounded-[5px] text-[#4a5464] text-[13px] font-normal absolute bottom-full left-1/2 mb-[10px] opacity-0 py-[5px] px-[7.5px] pointer-events-none whitespace-nowrap z-20;

	&:before {
		border-left: 4px solid #0000;
		border-right: 4px solid #0000;
		border-top: 4px solid #fff;
		@apply content-[''] h-0 w-0 absolute left-1/2 -bottom-[4px] -translate-x-1/2 z-20;
	}
}

.plyr__tooltip--visible {
	transform: translate(-50%) scale(1);
	@apply opacity-100;
}

.plyr__progress {
	@apply relative flex items-center mx-[8px];

	input[type=range] {
		@apply relative appearance-none block w-full z-20 h-[4px] bg-red border-none rounded-full;
	}

	input[type="range"]::-webkit-slider-thumb {
		@apply appearance-none bg-off-white rounded-full w-[20px] h-[20px] border-2 border-solid border-black;
  }

	input[type="range"]::-moz-range-thumb {
		@apply appearance-none bg-off-white rounded-full w-[20px] h-[20px] border-2 border-solid border-black;
  }
	
	.plyr__tooltip {
		@apply left-0 max-w-[120px] break-words;
	}
}
.plyr__progress__buffer {
	@apply appearance-none absolute top-1/2 left-0 w-full h-[4px] -mt-[2px] rounded-full;
}

.plyr__progress__buffer::-webkit-progress-bar {
	@apply bg-red rounded-full;
}
// none-standard, possible incompatibilities
.plyr__progress__buffer::-moz-progress-bar {
	@apply bg-red rounded-full;
}

.plyr--loading {
	.plyr__progress__buffer {
		animation: plyr-progress 1s linear infinite;
		background-image: linear-gradient(-45deg,#23282f99 25%,#0000 0,#0000 50%,#23282f99 0,#23282f99 75%,#0000 0,#0000);
		background-image: linear-gradient(-45deg,var(--plyr-progress-loading-background,#23282f99) 25%,#0000 25%,#0000 50%,var(--plyr-progress-loading-background,#23282f99) 50%,var(--plyr-progress-loading-background,#23282f99) 75%,#0000 75%,#0000);
		background-repeat: repeat-x;
		background-size: 25px 25px;
		background-size: var(--plyr-progress-loading-size,25px) var(--plyr-progress-loading-size,25px);
		color: #0000;
	}
}
.plyr--audio.plyr--loading {
	.plyr__progress__buffer {
		background-color: #c1c8d199;
		background-color: var(--plyr-audio-progress-buffered-background,#c1c8d199);
	}
}
.plyr__progress__marker {
	transform: translate(-50%,-50%);
	@apply bg-white rounded-[1px] h-[5px] absolute top-1/2 w-[3px] z-30;
}

.plyr__volume {
	@apply relative flex items-center max-w-[110px] md:max-w-[150px] nav-md:max-w-[202px] nav-md:w-[202px] nav-md:flex-shrink-0;

	input[type=range] {
		@apply relative appearance-none block w-full z-20 h-[4px] bg-teal-dark border-none rounded-full;
		@apply hidden sm:block;
	}

	input[type="range"]::-webkit-slider-thumb {
		@apply appearance-none bg-off-white rounded-full w-[20px] h-[20px] border-2 border-solid border-black;
  }

	input[type="range"]::-moz-range-thumb {
		@apply appearance-none bg-off-white rounded-full w-[20px] h-[20px] border-2 border-solid border-black;
  }
}

.plyr__control--overlaid {
	@apply hidden;

	&:focus {
		@apply opacity-100;
	}

	&:hover {
		@apply opacity-100;
	}
}

.plyr--playing {
	.plyr__control--overlaid {
		@apply opacity-0 invisible;
	}
}

.plyr--no-transition {
	transition: none !important;
}

.plyr__sr-only {
	clip: rect(1px,1px,1px,1px);
	border: 0 !important;
	height: 1px !important;
	overflow: hidden;
	padding: 0 !important;
	position: absolute !important;
	width: 1px !important;
}
// html {
//   font-size: 62.5%; // Use 10px for 1rem to make calculations easier.
//   scroll-behavior: smooth;
// }

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-smooth: never;
}

@layer base {
  // typography
  body, .body {
    @apply font-body font-normal text-black text-base md:text-base-lg;
  }
  a, .link {
    @apply text-black underline decoration-1 underline-offset-2 cursor-pointer hover:text-black hover:decoration-2 group-hover:text-black group-hover:decoration-2 md:decoration-2 md:hover:decoration-[3px] md:group-hover:decoration-[3px];

    &.link-red {
      @apply decoration-red hover:decoration-black group-hover:decoration-black;
    }

    &.link-strong {
      @apply decoration-2 underline-offset-[3px] hover:decoration-[3px] group-hover:decoration-[3px];
    }
    &.link-underline-thin {
      @apply md:decoration-1 md:hover:decoration-[2px] md:group-hover:decoration-[2px];
    }
    &.link-underline-medium {
      @apply decoration-[1.5px] hover:decoration-2 group-hover:decoration-2 md:decoration-2 md:hover:decoration-[3px] md:group-hover:decoration-[3px];
    }
    &.link-underline-semibold {
      @apply md:decoration-[3px] md:hover:decoration-[4px] md:group-hover:decoration-[4px];
    }
    &.link-underline-bold {
      @apply decoration-[3px] underline-offset-[4px] hover:decoration-[4px] group-hover:decoration-[4px];
    }
  }
  a.invert, .link-invert, .link-invert a {
    @apply text-white hover:text-white group-hover:text-white;
  }
  .link-no-underline {
    @apply no-underline hover:no-underline group-hover:no-underline;
  }
  strong, .strong {
    @apply font-bold;
  }
  h1, .heading-lg {
    @apply font-heading font-semibold text-xl md:text-xl-lg tracking-tightest md:-tracking-[1.4px];
  }
  h2, .heading-md {
    @apply font-heading font-semibold text-lg md:text-lg-lg mt-15 mb-14 md:mt-15-lg md:mb-14-lg tracking-tighter md:-tracking-[0.6px];
  }
  h3, .heading-sm {
    @apply font-heading-cond font-semibold text-h-sm md:text-h-sm-lg mb-2xs md:mb-xs;
  }
  .heading-xs {
    @apply font-body font-normal text-h-xs md:text-h-xs-lg mt-12 mb-6 md:mt-12-lg md:mb-6-lg;
  }
  .heading-2xs {
    @apply font-body font-normal text-h-2xs md:text-h-2xs-lg;
  }
  .heading-bold, .pull-quote {
    @apply font-heading font-semibold text-h-md md:text-h-md-lg tracking-tight md:-tracking-[0.42px];
  }
  .heading-light, .standfirst {
    @apply font-heading font-normal text-md md:text-md-lg mb-11 md:mb-11-lg tracking-tight md:-tracking-[0.72px];
  }
  .standfirst {
    @apply mt-4 md:mt-4-lg;
  }
  .body-md {
    @apply text-base md:text-base-lg;
  }
  .body-sm, .function-text {
    @apply text-sm md:text-sm-lg;

    strong, .strong {
      @apply font-semibold;
    }
  }
  .function-text {
    @apply font-function font-semibold;
  }
  .body-xs, .caption {
    @apply font-function text-xs mt-3 md:mt-4-lg tracking-wide;

    strong, .strong {
      @apply font-semibold;
    }

    a {
      @apply md:decoration-1 md:hover:decoration-[2px] md:group-hover:decoration-[2px];
    }
  }
  p, ol, ul {
    @apply mb-8 md:mb-8-lg;
  }
  ol {
    @apply [counter-reset:section];
  }
  ol li {
    @apply [counter-increment:section] relative pl-[24px] mb-3 last:mb-0 md:pl-[30px];
  }
  ol li:before {
    @apply [content:counters(section,'.')] absolute left-0 font-bold;
  }
  ul li {
    @apply relative pl-[24px] mb-4 last:mb-0 md:pl-[30px];
  }
  ul li:before {
    @apply content-[''] block absolute left-0 top-[9px] w-[8px] h-[8px] rounded-full bg-black md:top-[12px] md:w-[10px] md:h-[10px];
  }
  .list-reset li {
    @apply static pl-0 md:pl-0;
  }
  .list-reset li:before {
    @apply content-none;
  }
  // buttons
  button, 
  .button, 
  [type="button"], 
  [type="submit"] {
    @apply relative border-2 border-black rounded-none m-0 ml-[8px] mb-[8px] py-[8px] px-[20px] md:px-[24px] no-underline outline-none inline-flex justify-center items-center;
    @apply bg-texture bg-off-white-light;
    
    &:before {
      @apply content-[''] block absolute -top-[2px] -right-[2px] -bottom-[10px] -left-[10px];
      @apply bg-[url:theme("backgroundImage.btn-left"),theme("backgroundImage.btn-bottom")];
      @apply bg-[length:14px_auto,auto_14px];
      @apply bg-[position:-6px_top,right_calc(100%+6px)];
      @apply bg-no-repeat;
    }

    &:hover {
      @apply bg-off-white-dark;
    }

    &:active {
      @apply -translate-x-[8px] translate-y-[8px];

      &:before {
        @apply bg-none;
      }
    }

    & > * {
      @apply relative;
    }

    &:not(:last-child) {
      @apply mr-3xs;
    }
  }
  .button-large {
    @apply md:ml-[14px] md:mb-[14px];
    
    &:before {
      @apply md:-bottom-[16px] md:-left-[16px];
      @apply md:bg-[position:left_top,right_bottom];
    }
  }
  .button-reset {
    @apply static border-none rounded-none m-0 p-0 no-underline bg-none bg-transparent hover:bg-transparent before:content-none;

    &:active {
      @apply -translate-x-0 translate-y-0;
    }
  }
}